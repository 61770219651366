import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SpecialtySection = props => {
  const { specialty, specialityMessage, specialityImages, id } = props.currentPage || {};

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
  };

  if (!id) return null;

  const showingSpecialty = specialty === 'Fellowship in Radiology' ? 'Radiology Tech' : specialty;

  return (
    <div className='specialty-section'>
      <div className='inner-block first-block'>
        <h2 className='section-title'>{showingSpecialty}</h2>
        <p className='regular-text'>{specialityMessage}</p>
      </div>
      <div className='inner-block second-block'>
        {specialityImages?.length === 1 ? (
          <div>
            <img className='img-cover' alt={specialty} src={specialityImages?.[0]?.imageUrl} />
          </div>
        ) : (
          <Slider {...sliderSettings}>
            {specialityImages &&
              specialityImages.map((image, index) => (
                <div key={index}>
                  <img className='img-cover' alt={specialty} src={image.imageUrl} />
                </div>
              ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default SpecialtySection;

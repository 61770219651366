import React from 'react';
import { IconPlayFill, IconVideos } from 'shared/components/Icons';
import { bindDuration } from 'utils/appHelpers';
import LessonDefaultImage from 'assets/marketing/lesson_default_image.png';

const LessonsListSection = ({ lessons, activeLesson, setActiveLesson }) => {
  const lessonClickHandle = (lesson, e) => {
    setActiveLesson(lesson);
  };

  return (
    <div className='courses-list'>
      {lessons &&
        lessons.map((item, i) => {
          const lesson = item.lesson;
          const Icon = IconPlayFill;
          const active = (lesson?.id || lesson.title) === (activeLesson?.id || activeLesson?.title);
          const isFeedback = lesson.contentType === 5;
          const lessonCoverImg = lesson.imageUrl || lesson.image;
          return (
            <div
              key={i}
              onClick={lessonClickHandle.bind(null, lesson)}
              role='button'
              tabIndex='-1'
              className={`list-item d-flex pointer ${
                active ? 'active' : ''
              } justify-content-between`}
            >
              <div className='d-flex align-items-center'>
                <div aria-label='lessonPlay'>
                  <Icon ariaLabel='lessonPlay' />
                </div>
                <div className='lesson-image-area mx-3'>
                  <img src={lessonCoverImg || LessonDefaultImage} alt={lesson.title} />
                </div>
                <div className='d-flex flex-column'>
                  <p className='name'>{lesson.title}</p>
                  <p className='description'>{lesson.info || lesson.description}</p>
                </div>
              </div>
              {!isFeedback && (
                <div className='time-part d-flex flex-column justify-content-around align-items-end'>
                  {lesson.episodes && (
                    <span>
                      {lesson.episodes.length} <IconVideos />
                    </span>
                  )}
                  {lesson.duration && <p>{bindDuration(lesson.duration)}</p>}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LessonsListSection;

import React, { useEffect, Fragment } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState } from './Auth/actions';
import { useSnackbar } from 'notistack';

// Load Routes
import Main from './Main';
import Loading from 'shared/components/Loading';
import PublicViewer from './PublicViewer';
import Events from './Events';
import ComingSoon from './ComingSoon';
import PromoPage from './PromoPage';
import About from './Main/routes/About';
import PublicCaseView from './PublicCaseView';
import { WithTitleRoute, hasAccess } from 'utils/permissionHelper';
import PublicCourseView from './PublicCourseView';
import Login from './Auth/routes/Login';
import Forgot from './Auth/routes/Forgot';
import UserLicense from './Auth/routes/UserLicense';
import Reset from './Auth/routes/Reset';
import Register from './Auth/routes/Register';
import Verify from './Auth/routes/Verify';
import NoAccess from './Auth/routes/NoAccess';
import IncorrectLink from './Auth/routes/IncorrectLink';
import EmailVerification from './Auth/routes/EmailVerification';
import { MarketingLanding } from './Marketing';
import Product from './Product';
import ServiceAndMaintenance from './ServiceAndMaintenance';
import CookiePopup from 'shared/components/CookiePopup';

const IS_SERVICE_AND_MAINTENANCE = false;

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.isAuthenticated);
  const user = useSelector(state => state.account);
  const isVisitorUser = hasAccess('visitor_user');

  useEffect(() => {
    if (isAuth === null) dispatch(getAuthState(enqueueSnackbar));
    //eslint-disable-next-line
  }, [getAuthState, isAuth, user]);

  useEffect(() => {
    if (window.location.hostname.startsWith('www.')) {
      const newUrl = window.location.href.replace('www.', '');
      window.location.replace(newUrl);
    }
    setInterval(() => {
      document.querySelector('iframe')?.remove();
    }, 1000);
  }, []);

  // if (isAuth === -2) return <ServiceAndMaintenance />;

  if (isAuth === -1) return <ComingSoon />;

  return (
    <Fragment>
      <CookiePopup />
      <Router>
        {isAuth !== null && (
          <Switch>
            <WithTitleRoute path='/case/view/:id' component={PublicCaseView} />
            <WithTitleRoute path='/expromo1' component={PromoPage} />
            <WithTitleRoute path='/inpromo1' component={PromoPage} />
            <WithTitleRoute path='/soon' component={ComingSoon} />
            <WithTitleRoute path='/events' component={Events} />
            <WithTitleRoute path='/product' component={Product} />
            <WithTitleRoute path='/marketing-materials' component={MarketingLanding} />
            <WithTitleRoute path='/viewer' component={PublicViewer} />
            {/* For the shared course view only on logout state */}
            {!isAuth && <WithTitleRoute path='/about' component={About} />}
            {isVisitorUser && <WithTitleRoute path='/courses/:id' component={PublicCourseView} />}
            <WithTitleRoute path='/marketing-courses/:id' component={PublicCourseView} />
            {/* {isVisitorUser && <WithTitleRoute path='/case/view/:id' component={PublicCaseView} />} */}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/login' component={Login} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/forgot' component={Forgot} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/user/license' component={UserLicense} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/auth/reset/:code' component={Reset} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/register/:code?' component={Register} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/auth/verify/:code' component={Verify} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/no-web-access' component={NoAccess} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/incorrect-link' component={IncorrectLink} />
            )}
            {(isVisitorUser || isAuth == null) && (
              <WithTitleRoute path='/email-verification/:code?' component={EmailVerification} />
            )}
            <WithTitleRoute path='/' component={Main} />
          </Switch>
        )}
        {isAuth == null && <Loading classView='min-vh-100' />}
      </Router>
    </Fragment>
  );
};

export default App;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import logo from 'assets/ACE-logo.svg';
// import accessImg from 'assets/events/accesslogo.svg';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'app/Auth/actions';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { hasAccess } from 'utils/permissionHelper';

export const MarketingHeader = ({ history }) => {
  const dispatch = useDispatch();
  const [accountMenu, setAccountMenu] = useState();
  const isAuthUser = useSelector(state => state.isAuthenticated);
  const account = useSelector(state => state.account);
  const isVisitorUser = hasAccess('visitor_user');
  const isAuth = isAuthUser && !isVisitorUser;
  const accountMenuEl = useRef();

  const homeUrl = '/home';

  const logoutProfile = e => {
    e.preventDefault();
    dispatch(logout());
  };

  useOutsideClick(accountMenuEl, () => setAccountMenu(false));

  return (
    <div className='marketing-header d-flex flex-column justify-content-between'>
      <div className='event-nav'>
        <div className='container d-flex justify-content-between align-items-center'>
          <img src={logo} height='40' alt='logo' />
          <ul className='m-0 p-0 d-flex justify-content-end align-items-center'>
            {!isAuth && (
              <li>
                <NavLink activeClassName='active' to={homeUrl}>
                  Home
                </NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <NavLink activeClassName='active' to={'/courses'}>
                  My Transcript
                </NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <NavLink to={`/allcourses`}>Available Courses</NavLink>
              </li>
            )}
            <li>
              <NavLink to={`/marketing-materials`}>Marketing</NavLink>
            </li>
            {!!isAuth && (
              <li>
                <NavLink to={`/authors`}>Faculty</NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <NavLink to={`/about`}>About</NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <NavLink to={`/contacts`}>Support</NavLink>
              </li>
            )}
            {!isAuth && (
              <li>
                <NavLink activeClassName='active' to={`/register`}>
                  Sign Up
                </NavLink>
              </li>
            )}
            {!isAuth && (
              <li>
                <NavLink activeClassName='active' to={`/login`}>
                  Sign In
                </NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <Link className={'avatar-block'} to={'/profile'}>
                  <div className='avatar'>
                    <img src={account?.imageUrl} alt={account?.name} />
                  </div>
                  <div className='name-block'>
                    <p>{account?.firstName}</p>
                    <p>{account?.lastName}</p>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

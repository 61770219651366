import React from 'react';

import defaultBannerImg from 'assets/product/hover_course_cover.png';

const HoveredCourseDetails = ({ title, imageUrl }) => {
  return (
    <div className='hover-course-item'>
      <div className='banner'>
        <img alt='banner' src={imageUrl || defaultBannerImg} />
      </div>
      <p className='title'>{title}</p>
    </div>
  );
};

export default HoveredCourseDetails;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { COURSE_TYPES_BY_TYPENAME } from 'configs/constants';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import { MarketingIntroIcon } from 'shared/components/Icons';

export const MarketingBanner = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeSpotlight, setActiveSpotlight] = useState();

  const history = useHistory();
  const fourElementsArr = [
    {
      id: 1,
      count: '42',
      title: 'Courses',
      description: 'Courses available on HologicACE for all healthcare professionals',
      isRelativeForward: true,
      link: '/allcourses',
    },
    {
      id: 2,
      count: '80+',
      title: 'Patient Courses',
      description: 'Hands-on image review on a DICOM viewer',
      isRelativeForward: true,
      link: '/allcourses/3',
    },
    {
      id: 3,
      count: '14+',
      title: 'Accredited Programs',
      description: 'List of CME & CEU accredited on-demand programs',
      link: 'https://hologiced.com/breast-health/education-training/?_sfm_credit_type=cme',
    },
    {
      id: 4,
      count: '40+',
      title: 'Clinical Materials',
      description: 'Breast Health clinical evidence: Whitepapers and Summary Cards',
      link: 'https://hologiced.com/breast-health/clinical-library/',
    },
  ];

  const getActiveSpotlight = async () => {
    try {
      const { data } = await Api.get('/marketing-spotlight/active');
      if (data?.data) {
        setActiveSpotlight(data.data);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const goWithLink = (url, e) => {
    e.preventDefault();
    if (!url) return;
    confirmAlert({
      message:
        'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
      buttons: [
        {
          label: 'Continue',
          onClick: () => window.open(url, '_blank'),
        },
        {
          label: 'Close',
        },
      ],
    });
  };

  const handleClickItem = (e, item) => {
    if (item.isRelativeForward) {
      history.push(item.link);
    } else {
      goWithLink(item.link, e);
    }
  };

  const handleImageLoad = e => {
    const img = e.target;
    const { spaceWidth, spaceHeight, xCoordinate, yCoordinate, rotation = 0 } =
      activeSpotlight?.selectedFrame || {};
    const containerWidth = spaceWidth || 200;
    const containerHeight = spaceHeight || 200;

    const sizeRotationKoeficient = rotation ? rotation * 1.4 : 0;

    if (img.naturalHeight > img.naturalWidth) {
      img.style.height = `${containerHeight - sizeRotationKoeficient}px`;
      img.style.width = 'auto';
    } else {
      img.style.width = `${containerWidth - sizeRotationKoeficient}px`;
      img.style.height = 'auto';
    }

    img.style.position = 'absolute';

    if (rotation) {
      img.style.transform = `rotate(${rotation}deg)`;
      img.style.left = `${xCoordinate + (containerWidth - img.clientWidth - rotation * 1.1) / 2}px`;
      img.style.top = `${yCoordinate + (containerHeight - img.clientHeight + 60) / 2}px`;
    } else {
      img.style.left = `${xCoordinate + (containerWidth - img.clientWidth + 16) / 2}px`;
      img.style.top = `${yCoordinate + (containerHeight - img.clientHeight + 20) / 2}px`;
    }
  };

  useEffect(() => {
    getActiveSpotlight();
  }, []);

  return (
    <section className='marketing-banner'>
      <div className='absolute-transparent-overlay' />
      {/* Will be uncommented when designer give video for banner */}
      {/* <video autoPlay muted loop id='background-video'>
        <source src='/sample.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video> */}
      {!!activeSpotlight && (
        <div
          className={`spotlight-block ${!!activeSpotlight?.animation &&
            'hasAnimation'} ${!activeSpotlight.selectedFrame && 'has-no-frame'}`}
        >
          <img
            className='inner-img'
            alt={activeSpotlight.name}
            src={activeSpotlight.imageUrl}
            onLoad={handleImageLoad}
          />
          {!!activeSpotlight?.selectedFrame?.imageUrl && (
            <img
              width={300}
              className='frame'
              alt='frame'
              src={activeSpotlight.selectedFrame?.imageUrl}
            />
          )}
          {!!activeSpotlight?.selectedFrame?.hasFullName && (
            <p className='badge-name mb-0'>{activeSpotlight.fullName}</p>
          )}
        </div>
      )}

      <div className='banner-content'>
        <h1>Marketing Materials</h1>
        <p className='banner-desc'>
          The mission of Hologic Medical Education is to improve patient care through excellence in
          education, communication of clinical and scientific evidence, and partnerships with the
          healthcare community.
        </p>
        <a
          className='intro-button'
          href='https://www.hologicace.com/'
          target='_blank'
          rel='noreferrer'
        >
          <MarketingIntroIcon />
          All Courses
        </a>
      </div>
      <div className='courses-counts-block'>
        {fourElementsArr.map((item, idx) => {
          return (
            <div className='course-count-item' key={idx} onClick={e => handleClickItem(e, item)}>
              <h3 className='title mb-0'>
                <span>{item.count}</span> <span>{item.title}</span>
              </h3>
              <span className='description'>{item.description}</span>
            </div>
          );
        })}
      </div>
    </section>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import { findCourseFirstLesson, getEndOfSubs, isMobileDevice } from 'utils/appHelpers';
import HeaderSection from './components/HeaderSection';
import InfoSection from './components/InfoSection';
import LessonsListSection from './components/LessonsListSection';
import TypeVideo from './components/TypeVIdeo';
import TypePDF from './components/TypePDF';
import TypeCases from './components/TypeCases';
import TypeImage from './components/TypeImage';
import TypeQuiz from './components/TypeQuiz';
import Loading from 'shared/components/Loading';
import CourseFeedback from '../CourseFeedback';
import ProgressBar from 'shared/components/ProgressBar';
import ExpiredView from './components/ExpiredView';
import TagsViewBlock from 'shared/components/TagsViewBlock';
import NoAccessCourse from 'shared/components/NoAccessCourse';

import logo from 'assets/ace-logo-tsp.png';
import CourseHeader from '../CourseFeedback/FeedbackHeader';
import { getCourses } from '../Courses/actions';
import { Api } from 'utils/connectors';
import CoursePreview from 'app/PublicCourseView/CoursePreview';

const viewsComponents = {
  video: TypeVideo,
  pdf: TypePDF,
  cases: TypeCases,
  image: TypeImage,
  quiz: TypeQuiz,
};

const CourseView = ({ match, location, getCourses }) => {
  const courses = useSelector(state => state.courses);
  const marketingCourses = useSelector(state => state.marketingCourses);
  const history = useHistory();
  const quizAccessBlocked = location.state?.quizAccessBlocked;
  const needGetCourses = location.state?.needGetCourses;
  const [lesson, setLesson] = useState();
  const [isPdfExpanded, setIsPdfExpanded] = useState(false);

  const { courseId, type, lessonId, action } = match.params;

  const courseIdOrSharedToken = courseId;
  const isCourseIdShareToken = isNaN(courseId);
  const course = [...courses, ...marketingCourses]?.find(item =>
    isCourseIdShareToken
      ? item.accessToken === courseIdOrSharedToken
      : item.id === Number(courseId),
  );

  const [sharedCourse, setSharedCourse] = useState(null);

  const onCasePlayClick = () => {};

  const getSharedCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/shared/course/${courseIdOrSharedToken}`);
      sessionStorage.setItem('courseSharedToken', courseIdOrSharedToken);
      setSharedCourse(data.data);
    } catch (err) {
      history.push('/no-web-access');
    }
  };

  useEffect(() => {
    if (course) {
      const activeLesson =
        course?.lessons?.find(lesson => lesson.id === Number(lessonId)) || course?.lessons?.[0];
      setLesson(activeLesson);
      if (!type || !lessonId) {
        if (activeLesson) {
          history.replace(`/courses/${courseId}/${activeLesson.type}/${activeLesson.id}`);
        }
      }
    }
    //eslint-disable-next-line
  }, [match.params]);

  useEffect(() => {
    if (isCourseIdShareToken) {
      getSharedCourseData();
    }
  }, [courseIdOrSharedToken]);

  useEffect(() => {
    if (needGetCourses) getCourses();
  }, [needGetCourses]);

  if (isCourseIdShareToken) return <CoursePreview course={sharedCourse} />;

  if (!course && !isCourseIdShareToken) return <NoAccessCourse />;

  const { expirable, expire, isOpened } = course;
  const isExpired = expirable && expire ? moment(expire).diff(new Date()) <= 0 : false;

  if ((!type || !lessonId) && !courseIdOrSharedToken) {
    findCourseFirstLesson(course, history);
    return null;
  }

  if (isExpired)
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection course={course} history={history} />
        <ExpiredView course={course} />
      </div>
    );

  if (type === 'feedback') {
    return <CourseFeedback course={course} lesson={lesson} match={match} />;
  }

  if (type === 'quiz' && quizAccessBlocked) {
    return (
      <div className='certificate-page'>
        <CourseHeader history={history} course={course} />
        <div className='text-center py-5'>You need to complete all lessons to access quiz.</div>
      </div>
    );
  }

  if (
    ((!lesson || lesson.id !== Number(lessonId)) && !isCourseIdShareToken) ||
    (isCourseIdShareToken && !course)
  ) {
    return <Loading />;
  }

  const LessonView = viewsComponents[type];

  if (course?.isMarketingMaterial && isMobileDevice()) {
    return (
      <div className='marketing-course-mobile-view'>
        <div className='marketing-header d-flex p-0 align-items-center'>
          <div className='event-nav'>
            <div className='container d-flex justify-content-between'>
              <img src={logo} height='60' alt='logo' />
            </div>
          </div>
        </div>
        {!!course?.courseLessons?.[0]?.lesson && (
          <LessonView
            course={course}
            lesson={course?.courseLessons?.[0]?.lesson}
            onCasePlayClick={onCasePlayClick}
          />
        )}
      </div>
    );
  }

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection course={course} history={history} />
      <div className={`body-section overflow-hidden d-flex type-${type}`}>
        <LessonView
          setLesson={setLesson}
          lessonId={lessonId}
          currentLessons={course.lessons}
          lesson={lesson}
          course={course}
          params={match.params}
          history={history}
          action={action}
          isExpandedView={isPdfExpanded}
          setIsExpandedView={setIsPdfExpanded}
        />
      </div>
      <div className='footer-section d-flex'>
        <div className='col courses-area p-0'>
          <LessonsListSection
            isQuizAvailable={course.isQuizAvailable}
            lessons={course.lessons}
            activeLesson={lesson}
            courseId={courseId}
            history={history}
          />
        </div>
        <div className='col info-area'>
          <InfoSection info={course.description} />
          <div className='course-info-progress d-flex flex-column justify-content-center align-items-center'>
            {!isOpened && (
              <div className='col-6'>
                <ProgressBar progress={course.progress} />
              </div>
            )}
            <p>{getEndOfSubs(course.expire, course.expirable)}</p>
          </div>
          <TagsViewBlock tags={course.courseTags} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  courses: state.courses,
});

const mapDispatchToProps = {
  getCourses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseView);

import React from 'react';
import SpecialistCourses from './SpecialistCourses';

const RequiredCoursesSection = props => {
  const { product, user } = props;
  const userHasEntity = user?.entities?.length;

  const userSpecialty = user?.learnerProfile?.speciality;

  return (
    <SpecialistCourses
      userSpecialty={userSpecialty}
      userHasEntity={userHasEntity}
      productId={product.id}
      {...product}
    />
  );
};

export default RequiredCoursesSection;
